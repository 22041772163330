<template>
  <div
    class="d-flex pricing-card mx-auto flex-wrap"
    :class="enterprisePlanFilter ? 'justify-content-center justify-content-lg-between':'justify-content-center'"
  >
    <div
      data-testid="left-box-popular"
      class="d-flex flex-column box mb-2 popular"
    >
      <div class="d-flex flex-column w-100">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <img
            alt="img"
            style="width: 32px"
            :src="smallLogo('regular')"
          >
          <div
            data-testid="content-section-regular"
            class="popular-tag font-weight-600 d-flex justify-content-center align-items-center text-uppercase"
          >
            {{ isTrialing ? $t('pricingPage.textDayFeeTrial', {trialDay: trialDay}) : $t('pricingPage.textPopular') }}
          </div>
        </div>
        <p
          data-testid="name-left-box"
          class="font-weight-600 text-capitalize font-20 name"
        >
          {{ enterprisePlanFilter ? 'Regular' : 'Partnership Member' }}
        </p>
        <p
          class="font-14"
          style="margin-bottom: 10px"
        >
          {{ $t('pricingPage.textPerfectPlanForStarters') }}
        </p>

        <hr class="divider">

        <div class="mt-1">
          <h1
            data-testid="amount-plan-left-box"
            class="font-medium mb-0 font-32"
          >
            {{ amount(regularPlanAmount) }}
          </h1>
          <span class="time-cycle font-14">
            {{ isTrialing ? $t('pricingPage.textChargeAfterDays', {trialDay: trialDay}) : $t('pricingPage.textMonthly') }}
          </span>
        </div>
      </div>
      <div class="plan-select my-1">
        <v-select
          v-model="regularPlanSelected"
          id="select-plan"
          data-testid="select-plan"
          label="name"
          placeholder="Select plan"
          :clearable="false"
          :searchable="false"
          :options="regularOptions"
          :loading="fetchPlanLoading"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <feather-icon
                icon="ChevronDownIcon"
                size="20"
              />
            </span>
          </template>

          <template #option="{ name, value }">
            <strong
              :id="`plan-${value}`"
              :data-testid="`plan-${value}`"
            >{{ value }}</strong>
          </template>
        </v-select>
      </div>
      <btn-loading
        id="btn-upgrade-regular"
        variant-convert="btn-submit"
        data-testid="btn-upgrade-regular"
        class="font-medium mb-1 d-flex align-items-center justify-content-center"
        style="height: 48px"
        pill
        block
        @click="goToCheckoutPage('regular')"
      >
        {{ btnText }}
      </btn-loading>
      <div class="benefits mt-1">
        <p class="font-weight-600 font-14">
          {{ $t('pricingPage.textWhatIncluded') }}:
        </p>
        <div
          v-for="(item, i) in regularPlanBenefits"
          :key="i"
          class="mb-1 d-flex align-items-center"
        >
          <img
            alt="tick"
            :src="require('@/assets/images/common/ic-tick-circle-yellow.svg')"
            class="mr-1"
          >
          <span
            :data-testid="`text-benefit${i}-regular`"
            class="font-14"
          >
            {{ item }}
          </span>
        </div>
      </div>
      <hr class="divider">
      <div class="benefits mt-1">
        <p class="font-medium font-14">
          {{ $t('pricingPage.textPaymentMethod') }}
        </p>
        <div class="d-flex flex-wrap">
          <b-row>
            <b-col
              v-for="(item, i) in listPaymentMethod"
              :key="i"
              md="6"
              cols="12"
              class="mb-1 d-flex align-items-center w-50"
            >
              <img
                alt="tick"
                :src="require('@/assets/images/common/ic-tick-circle-green.svg')"
                class="mr-1"
              >
              <p
                :data-testid="`regular-payment-method-${item}`"
                class="font-14 m-0 d-flex align-items-center"
              >
                {{ item.fullName }}
                <!-- v-if="checkShowRecommended(item)" -->
                <span
                  v-if="false"
                  class="text-recommend"
                >{{ $t('payment.textRecommended') }}</span>
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div
      v-if="enterprisePlanFilter"
      data-testid="right-box-enterprise"
      class="d-flex flex-column box mb-2"
      style="border: 1px solid rgba(61, 64, 91, 0.1)"
    >
      <div class="d-flex flex-column w-100">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <img
            alt="img"
            style="width: 32px"
            :src="smallLogo()"
          >
          <div
            v-if="false"
            class="popular-tag font-weight-600 d-flex justify-content-center align-items-center text-uppercase"
          >
            {{ $t('pricingPage.text14DaysTrial') }}
          </div>
        </div>
        <p
          data-testid="name-right-box"
          class="font-weight-600 text-capitalize font-20 name"
        >
          Enterprise
        </p>
        <p
          class="font-14"
          style="margin-bottom: 10px"
        >
          {{ $t('pricingPage.textRunYourCompany') }}
        </p>

        <hr class="divider">

        <div class="mt-1">
          <h1
            data-testid="amount-plan-right-box"
            class="font-medium mb-0 font-32"
          >
            {{ amount(enterprisePlanAmount) }}
          </h1>
          <span class="time-cycle font-14">{{ $t('pricingPage.textMonthly') }}</span>
        </div>
      </div>
      <btn-loading
        id="btn-upgrade-enterprise"
        data-testid="btn-upgrade-enterprise"
        variant-convert="btn-submit"
        class="font-medium my-1"
        style="height: 48px"
        pill
        block
        @click="goToCheckoutPage('enterprise')"
      >
        {{ btnEnterpriseText }}
      </btn-loading>
      <div class="e-benefits">
        <p class="font-weight-600 font-14">
          {{ $t('pricingPage.textWhatIncluded') }}:
        </p>
        <div
          v-for="(item, i) in enterPrisePlanBenefits"
          :key="i"
          class="mb-1 d-flex align-items-center"
        >
          <img
            alt="tick"
            :src="require('@/assets/images/common/ic-tick-circle-yellow.svg')"
            class="mr-1"
          >
          <span
            :data-testid="`text-benefit${i}-enterprise`"
            class="font-14"
          >{{ item }}</span>
        </div>
      </div>
      <hr class="divider">
      <div class="benefits mt-1">
        <p class="font-medium font-14">
          {{ $t('pricingPage.textPaymentMethod') }}
        </p>
        <div class="d-flex flex-wrap">
          <b-row>
            <b-col
              v-for="(item, i) in listPaymentMethod"
              :key="i"
              md="6"
              cols="12"
              class="mb-1 d-flex align-items-center w-50"
            >
              <img
                alt="tick"
                :src="require('@/assets/images/common/ic-tick-circle-green.svg')"
                class="mr-1"
              >
              <p
                :data-testid="`enterprise-payment-method-${item}`"
                class="font-14 m-0 d-flex align-items-center"
              >
                {{ item.fullName }}
                <!-- v-if="checkShowRecommended(item)" -->
                <span
                  v-if="false"
                  class="text-recommend"
                >{{ $t('payment.textRecommended') }}</span>
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import {BButton,
  BRow,
  BCol
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import pricingMixin from '@/mixins/pricingMixin'
import envMixin from '@/mixins/envMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

  export default {
    components: {
      BtnLoading,
      BButton,
      BRow,
      BCol,
      vSelect,
    },
    mixins: [pricingMixin, envMixin],

    computed:{
      checkShowRecommended(){
        return item => item === 'Airwallex'
      },

      trialDay() {
        return this.user?.data?.plans?.subscription?.trial_days || 0
      },
    },

    methods: {
      smallLogo(type) {
        if (type) {
          // return process.env.VUE_APP_ENTERPRISE_PLAN_LOGO || require('@/assets/images/pages/billing/business.svg')
          return process.env.VUE_APP_ENTERPRISE_PLAN_LOGO || require('@/assets/images/pages/billing/regular.svg')
        } else {
          return process.env.VUE_APP_REGULAR_PLAN_LOGO || require('@/assets/images/pages/billing/enterprise.svg')
        }
      }
    }
  }
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
  @import '@/assets/scss/variables/_variables.scss';

  .pricing-card {
    max-width: 864px;
  }

  .box {
    border-radius: var(--border-radius-lg);
    padding: 20px 20px;
    // height: 650px;
    width: 420px;


    p, h1 {
      color: #16213E;
    }

    .name {
      margin-bottom: 10px;
      color: #16213E;
    }

    .time-cycle {
      color: rgba(22, 33, 62, 0.5);
    }

    .e-benefits {
      margin-top: 80px;
    }

    &.popular {
      box-shadow: 1px 4px 24px 0 rgb(34 41 47 / 10%);

      .popular-tag {
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 100px;
        font-size: 12px;
        padding: 0 10px;
        height: 25px;
      }
    }
  }

  .divider {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  .bg-button {
    border: 0px;
    background: linear-gradient(145.93deg, #81FFF4 -17.96%, #6E44FF 63.03%);
  }
</style>
<style lang="scss">
  .plan-select {

    .vs__dropdown-menu {
      li {
        font-size: 16px !important;
      }
    }

    cursor: pointer !important;

    .v-select.vs--single .vs__selected {
      margin-top: 0;
    }

    .vs--open .vs__dropdown-toggle {
      border-color: white;
    }

    .vs--single .vs__dropdown-toggle {
      border-radius: var(--btn-border-radius-base);
      height: 48px;
    }

    .vs__actions {
      padding-top: 0;
    }

    .vs__dropdown-toggle {
      padding: 5px 0 5px 0;
    }

    .vs__actions {
      padding: 0 6px 0 3px;
    }

    .vs__search {
      margin: 1px 0 0 0;
    }

    .v-select.vs--single .vs__selected, .vs--single.vs--open .vs__selected {
      margin-left: 10px;
      transform: translateX(0);
    }
  }

  .text-recommend{
    text-transform: uppercase;
    background-color: #FF9903;
    font-size: 10px;
    padding: 0 6px;
    margin-left: 6px;
    border-radius: 6px;
    color: #fff;
  }
</style>
