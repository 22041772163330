<template>
  <div>
    <b-row v-show="!showSuccessPage">
      <b-col
        v-if="!loading && checkoutData.isContinuePurchase === false"
        cols="12"
        class="mb-1"
      >
        <btn-loading
          variant-convert="dark"
          data-testid="btn-change-plan-checkout"
          class="px-1 mb-1 btn-primary-color"
          pill
          size="sm"
          @click="$emit('update:showCheckoutPage', false)"
        >
          <feather-icon
            icon="ChevronLeftIcon"
            class="mr-20"
          />
          {{ $t('billingPage.banner.textChangePlan') }}
        </btn-loading>
      </b-col>

      <!-- List Credit Card -->
      <b-col
        md="8"
        xs="12"
      >
        <!--    warning message-->
        <div
          v-if="isShowWarningAddCard"
          :class="hasStepGuide ? 'warning-has-step' : ''"
        >
          <div class="warning-message">
            <div class="d-flex align-items-center mb-1">
              <img
                class="alert-img mr-50"
                alt="alert"
                :src="require('@/assets/images/common/ic-alert.svg')"
              >
              <h3 class="mb-0">
                {{ $t('addFundPage.titleWarningReAddCard') }}
              </h3>
            </div>
            <p>
              {{ $t('addFundPage.contentWarningReAddCard') }}
            </p>
            <p class="font-14">
              {{ $t('addFundPage.subWarningReAddCard') }}
            </p>
            <user-hint class="font-14" />
          </div>
        </div>
        <!--    warning message-->

        <div
          data-testid="section-list-card"
          class="manager-list-card"
        >
          <div class="credit-box d-flex flex-column">
            <div class="text-placeholder bg-white list-card px-0">
              <div class="list-header">
                <b-row class="mx-2 my-1">
                  <b-col
                    cols="12"
                    md="4"
                    class="pl-0"
                  >
                    {{ $t('addFundPage.creditCardPayment.testMyCreditCards') }}
                  </b-col>
                  <b-col
                    cols="6"
                    md="4"
                    class="d-none d-md-block"
                  >
                    {{ $t('addFundPage.creditCardPayment.textCardID') }}
                  </b-col>
                </b-row>
                <hr class="hr-custom">
              </div>
              <div
                class="list-body"
                :class="{ 'pa-1': loading && listCard.length === 0 }"
              >
                <b-overlay
                  :show="showOverLay"
                  spinner-small
                  opacity=".3"
                  blur="blur"
                  rounded="sm"
                >
                  <div
                    v-if="listCard.length > 0"
                    id="credit-card-management"
                    data-testid="card-pricing-management"
                  >
                    <credit-card-item
                      v-for="(item, index) in listCard"
                      :key="index"
                      :id="`card-pricing-${index+1}`"
                      :data-testid="`card-pricing-${index+1}`"
                      :card="item"
                      :index="index"
                      :is-active="item.default"
                      :has-only-one-card="!!(listCard.length === 1)"
                      @chooseCard="chooseCard"
                      @deleteCard="deleteCard"
                      @cancelTrial="cancelTrial"
                    />
                  </div>
                  <div
                    v-else
                    class="text-center my-2"
                  >
                    <img
                      :src="
                        require('@/assets/images/common/no-credit-card-found.png')
                      "
                      alt="image"
                      data-testid="card-not-found"
                      class="img-not-found mb-1"
                    >
                    <p>{{ $t('addFundPage.creditCardPayment.textNoCreditCard') }}</p>
                  </div>
                  <hr :class="{ 'hr-no-margin': listCard.length > 0 }">
                </b-overlay>
              </div>
              <div class="list-footer">
                <b-row class="mx-2 my-1">
                  <b-col
                    cols="12"
                    class="text-center cursor-pointer"
                    @click="toggleAddNewCard"
                  >
                    <div
                      data-testid="btn-card"
                      class="d-flex align-items-center justify-content-center"
                    >
                      <feather-icon
                        size="20"
                        class="btn-add-icon"
                        :icon="showAddNewCard ? 'MinusIcon' : 'PlusIcon'"
                      />
                      <span class="btn-add">{{
                        showAddNewCard ? $t('common.btnClose') : $t('addFundPage.creditCardPayment.textAddCreditCard')
                      }}</span>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>

          <div
            v-if="errorStripe"
          >
            <error-tag :error="errorStripe" />
          </div>
        </div>

        <div
          class="my-1 credit-form px-0"
          :class="{ 'my-2': showAddNewCard }"
        >
          <credit-card-form
            v-if="stripeElements"
            :card-data="card"
            :card-cvc-error="cardCvcError"
            :card-expiry-error="cardExpiryError"
            :card-number-error="cardNumberError"
            :card-name-error="cardNameError"
            :card-email-error="cardEmailError"
            :save-card-loading="saveCardLoading"
            :show-add-new-card="showAddNewCard"
            :save-card-error="saveCardError"
            @change-name="onChangeName"
            @change-email="onChangeEmail"
            @save-card="saveCard"
          />
          <warning-notification
            v-else
          >
            <template #title>
              <div class="d-flex">
                <img
                  class="alert-img mr-50"
                  alt="alert"
                  width="15"
                  :src="require('@/assets/images/common/ic-alert.svg')"
                >
                <p class="font-weight-bold mb-0">
                  {{ $t('payment.creditCard.textAddNewCardUnavailable') }}
                </p>
              </div>
            </template>
          </warning-notification>
        </div>

        <div class="user-hint mt-1 mb-2 d-flex">
          <user-hint />
        </div>
      </b-col>

      <!-- Checkout Form -->
      <b-col
        md="4"
        xs="12"
        data-testid="section-checkout-summary"
        class="checkout-summary ml-auto"
      >
        <div>
          <billing-checkout-summary
            :checkout-data="checkoutData"
            :is-monthly="isMonthly"
            :is-reset-error="isResetError"
            @show-success-page="showSuccess"
            @reset-error="resetError"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import { PaymentService } from '@/services'
import { toastification } from '@core/mixins/toast'
import { getResponse } from '@/plugins/store'
import { PAYMENT_METHOD } from '@/constants'
import axios from '@axios'
import _get from 'lodash/get'
import Vue from 'vue'
import { StripePlugin } from '@vue-stripe/vue-stripe'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import authMixin from '@/mixins/authMixin'
import UserHint from '@/components/UserHint.vue'
import errorTag from '@/components/errorTag.vue'
import WarningNotification from '@/views/home/components/WarningNotification.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import CreditCardItem from '../../payment/components/CreditCard/CreditCardItem.vue'
import CreditCardForm from '../../payment/components/CreditCard/CreditCardForm.vue'
import BillingCheckoutSummary from './BillingCheckoutSummary.vue'

const { mapMutations, mapGetters, mapActions } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
const { mapGetters: mapGettersBilling, mapActions: mapActionsBilling } = createNamespacedHelpers('billing')

export default {
  components: {
    BtnLoading,
    WarningNotification,
    errorTag,
    BillingCheckoutSummary,
    CreditCardForm,
    CreditCardItem,
    UserHint,
    BRow,
    BCol,
    BOverlay,
  },
  mixins: [toastification, stepGuideMixin, authMixin],
  props: {
    checkoutData: {
      type: Object,
      required: true,
      default: () => {},
    },
    isMonthly: {
      type: Boolean,
      required: true,
      default: true,
    },
    showCheckoutPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSuccessPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      // card element
      card: {
        cvc: '',
        number: '',
        expiry: '',
        email: '',
        name: '',
      },

      errorStripe: '',
      saveCardError: '',
      isResetError: false,

      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,

      // card errors
      cardCvcError: '',
      cardExpiryError: '',
      cardNumberError: '',
      cardNameError: '',
      cardEmailError: '',

      showAddNewCard: false,

      // loading
      saveCardLoading: false,
      setCardDefaultLoading: false,
      reChargeLoading: false,
      deleteCardLoading: false,
      initCardLoading: false,
      cancelTrialLoading: false,

      // const
      PAYMENT_METHOD,

      showCKPage: false,
      stripeElements: null,
    }
  },

  computed: {
    ...mapGetters(['listCard', 'loading']),
    ...mapGettersAuth(['user', 'plansStatus']),
    ...mapGettersBilling(['subscriptionStatus']),

    // stripeElements() {
    //   return this.$stripe.elements()
    // },

    needToAddCardToStripeUS() {
      return _get(this.user, ['data', 'needToAddCardToStripeUS'])
    },

    isShowWarningAddCard() {
      return this.needToAddCardToStripeUS
    },

    checkFormValid() {
      return !!(
        this.card.number
          && this.card.expiry
          && this.card.cvc
          && this.card.email
          && this.card.name
          && this.cardNumberError === ''
          && this.cardExpiryError === ''
          && this.cardCvcError === ''
          && this.cardEmailError === ''
          && this.cardNameError === ''
      )
    },

    showOverLay() {
      return (
        this.setCardDefaultLoading
          || this.loading
          || this.deleteCardLoading
          || this.initCardLoading
          || this.cancelTrialLoading
      )
    },

    fullName() {
      return _get(this.user, ['data', 'fullName'], '')
    },

    email() {
      return _get(this.user, ['data', 'email'], '')
    },
  },

  watch: {
    user: {
      async handler(currentUser) {
        const locale = localStorage.getItem('locale') || process.env.VUE_APP_LOCALE
        const pk = currentUser?.data?.billings?.stripe?.stripe_pk
        if (!pk) return

        const options = {
          pk,
          locale,
        }
        Vue.use(StripePlugin, options)
        this.stripeElements = this.$stripe.elements()
        this.initFormElement()
      },
      deep: true,
      immediate: true,
    },

    saveCardError: {
      handler(error) {
        if (error) {
          window.scrollTo(0, document.body.scrollHeight)
        }
      },
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    this.getListCard()
    // this.initFormElement()
  },

  beforeDestroy() {
    if (this.stripeElements) {
      this.cardNumber.destroy()
      this.cardExpiry.destroy()
      this.cardCvc.destroy()
    }
  },

  methods: {
    ...mapActions(['getListCard']),
    ...mapActionsAuth(['getAccountInfo']),
    ...mapMutations(['SET_CARD_DEFAULT']),
    ...mapActionsBilling(['getSubscriptionStatus']),

    initFormElement() {
      const style = {
        base: {
          color: '#16213E',
          lineHeight: '44px',
          fontWeight: 300,
          fontFamily: 'Mulish, sans-serif',
          fontSize: '16px',
          '::placeholder': {
            color: '#B9B9C3',
            fontSize: '16px',
          },
          invalid: {
            iconColor: '#E11C4E',
            color: '#E11C4E',
          },
        },
      }

      this.$nextTick(() => {
        this.cardNumber = this.stripeElements.create('cardNumber', {
          style,
        })
        this.cardNumber.mount('#card-number')

        this.cardExpiry = this.stripeElements.create('cardExpiry', {
          style,
        })
        this.cardExpiry.mount('#card-expiry')

        this.cardCvc = this.stripeElements.create('cardCvc', {
          style,
        })
        this.cardCvc.mount('#card-cvc')

        this.listenForErrors()
      })
    },

    listenForErrors() {
      this.cardNumber.addEventListener('change', event => {
        this.cardNumberError = ''
        this.toggleError(event, 'cardNumber')
        this.card.number = !!event.complete
      })

      this.cardExpiry.addEventListener('change', event => {
        this.cardExpiryError = ''
        this.toggleError(event, 'cardExpiry')
        this.card.expiry = !!event.complete
      })

      this.cardCvc.addEventListener('change', event => {
        this.cardCvcError = ''
        this.toggleError(event, 'cardCvc')
        this.card.cvc = !!event.complete
      })
    },

    toggleError(event, type) {
      if (event.error) {
        switch (type) {
          case 'cardNumber':
            this.cardNumberError = event.error.message
            break
          case 'cardExpiry':
            this.cardExpiryError = event.error.message
            break
          case 'cardCvc':
            this.cardCvcError = event.error.message
            break
          default:
            break
        }
      }
    },

    resetError() {
      this.errorStripe = ''
      this.saveCardError = ''
      this.isResetError = false
    },

    async chooseCard(card) {
      this.resetError()
      try {
        this.setCardDefaultLoading = true
        await PaymentService.setCardDefault(card)
        this.SET_CARD_DEFAULT(card)
        this.setCardDefaultLoading = false
      } catch (e) {
        this.setCardDefaultLoading = false
        // this.toastFailure(e.data.message || 'Set card default error!')
        this.errorStripe = e.data.message || this.$t('payment.creditCard.errorSetCardFail')
      }
    },

    async deleteCard(id) {
      this.resetError()
      this.isResetError = true
      this.deleteCardLoading = true
      try {
        await PaymentService.deleteCard(id)
        this.deleteCardLoading = false
        this.getListCard()
      } catch (e) {
        this.deleteCardLoading = false
        // this.toastFailure(e.data.message || 'Delete Card Failure!')
        this.errorStripe = e.data.message || this.$t('payment.creditCard.toastRemoveCardFail')
      }
    },

    async saveCard() {
      this.resetError()
      this.isResetError = true
      if (!this.checkFormValid) {
        // Validate form manually
        if (!this.card.number && !this.cardNumberError) {
          this.cardNumberError = this.$t('payment.creditCard.errorCardNumberIncomplete')
        } else if (!this.cardNumberError) {
          this.cardNumberError = ''
        }

        if (!this.card.expiry && !this.cardExpiryError) {
          this.cardExpiryError = this.$t('payment.creditCard.errorCardExpiredIncomplete')
        } else if (!this.cardExpiryError) {
          this.cardExpiryError = ''
        }

        if (!this.card.cvc && !this.cardCvcError) {
          this.cardCvcError = this.$t('payment.creditCard.errorCardSecurityIncomplete')
        } else if (!this.cardCvcError) {
          this.cardCvcError = ''
        }

        this.checkEmailValid('onSubmit')
        this.checkNameValid()
      } else {
        this.saveCardLoading = true

        try {
          const { data } = await PaymentService.setUpIntent()
          const { name, email } = this.card
          const resultConfirm = await this.$stripe.confirmCardSetup(
            getResponse(data).clientSecret,
            {
              payment_method: {
                card: this.cardNumber,
                billing_details: {
                  email,
                  name,
                },
              },
            },
          )

          if (resultConfirm.error) {
            this.saveCardLoading = false
            // this.toastFailure(resultConfirm.error.message || 'Serve error!')
            this.saveCardError = resultConfirm.error.message || this.$t('payment.creditCard.toastServeError')
          } else {
            try {
              const dataToPost = {
                paymentMethodId: resultConfirm.setupIntent.payment_method,
                setupIntentClientSecret: getResponse(data).clientSecret,
                default: true,
              }

              await PaymentService.saveCard(dataToPost)
              this.saveCardLoading = false
              this.getListCard()
              this.resetAddNewCardForm()
              this.closeAddNewCard() // close add new credit card form
            } catch (e) {
              this.saveCardLoading = false
              // this.toastFailure(e?.data?.message || 'Save card error')
              this.saveCardError = e.data.message || this.$t('payment.creditCard.toastSaveCardFail')
            }
          }
        } catch (e) {
          this.saveCardLoading = false
          // this.toastFailure(e?.data?.message || 'Save card error')
          this.saveCardError = e.data.message || this.$t('payment.creditCard.toastSaveCardFail')
        }
      }
    },

    resetAddNewCardForm() {
      this.cardNumber.clear()
      this.cardExpiry.clear()
      this.cardCvc.clear()
      this.card.email = ''
      this.card.name = ''
    },

    toggleAddNewCard() {
      this.showAddNewCard = !this.showAddNewCard
    },

    closeAddNewCard() {
      this.showAddNewCard = false
    },

    showSuccess() {
      this.$emit('update:showSuccessPage', true)
      this.$emit('update:showCheckoutPage', false)
    },

    async cancelTrial(idCardPayment) {
      this.isResetError = true
      this.resetError()
      try {
        this.cancelTrialLoading = true
        await this.getSubscriptionStatus()
        const { id } = this.subscriptionStatus
        await axios.put(`/api/stripe-payment/subscriptions/${id}/cancel`)
        await this.deleteCard(idCardPayment)
        this.cancelTrialLoading = false
        this.getAccountInfo() // update plansStatus again
        this.getSubscriptionStatus() // update current subscription again to show alert confirm payment or not
      } catch (e) {
        this.cancelTrialLoading = false
        // this.toastFailure(e.data.message || 'Cancel Subscription Failure!')
        this.errorStripe = e.data.message || this.$t('payment.creditCard.toastCancelSubscriptionFail')
      }
    },

    checkEmailValid(type) {
      const { email } = this.card
      const mailFormat = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/
      if (type !== 'onChange') {
        if (!email) {
          this.cardEmailError = this.$t('payment.creditCard.errorMailIncomplete')
        } else if (!email.match(mailFormat)) {
          this.cardEmailError = this.$t('payment.creditCard.errorMailInvalid')
        }
      } else if (!email.match(mailFormat)) {
        this.cardEmailError = this.$t('payment.creditCard.errorMailInvalid')
      }
    },

    checkNameValid() {
      const { name } = this.card
      if (!name) {
        this.cardNameError = this.$t('payment.creditCard.errorNameIncomplete')
      }
    },

    onChangeEmail(value) {
      this.cardEmailError = ''
      this.card.email = value
      if (value) {
        this.checkEmailValid('onChange')
      }
    },

    onChangeName(value) {
      const validate = / {2,}/
      this.cardNameError = ''
      this.card.name = value
      if (validate.test(value)) {
        this.cardNameError = this.$t('payment.creditCard.errorNameCardInvalid')
      }
    },

    // async initCardBilling() {
    //   try {
    //     this.initCardLoading = true
    //     const { data } = await PaymentService.initCard()
    //
    //     const { status, clientSecret, paymentMethod } = getResponse(data)
    //
    //     if (status === 'succeeded') {
    //       this.$refs['modal-billing-code'].showModal()
    //       this.initCardLoading = false
    //     }
    //     if (status === 'requires_action') {
    //       this.confirmInitCardBilling(clientSecret, paymentMethod)
    //     }
    //   } catch (e) {
    //     this.initCardLoading = false
    //     this.toastFailure(e?.data?.message)
    //   }
    // },

    // async confirmInitCardBilling(clientSecret, defaultPaymentMethod) {
    //   try {
    //     const resultConfirm = await this.$stripe.confirmCardPayment(
    //       clientSecret,
    //       {
    //         payment_method: defaultPaymentMethod,
    //       },
    //     )
    //
    //     if (resultConfirm.error) {
    //       this.initCardLoading = false
    //       this.toastFailure(resultConfirm?.error?.message || 'Init card failure!')
    //       return
    //     }
    //
    //     const { status } = resultConfirm.paymentIntent
    //
    //     if (status === 'succeeded') {
    //       this.initCardLoading = false
    //       this.$refs['modal-billing-code'].showModal()
    //       await PaymentService.fulFill({ paymentIntentId: resultConfirm.paymentIntent.id })
    //     }
    //   } catch (e) {
    //     this.initCardLoading = false
    //     this.toastFailure(e.message)
    //   }
    // },

    openLiveChat() {
      if (window.fcWidget) {
        window.fcWidget.open()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/variables/_variables.scss";

  .manager-list-card{
    padding: 24px;
    background-color: #fff;
    border-radius: var(--border-radius-base);
  }

  .list-card {
    border-radius: var(--border-radius-base);
    max-width: 1000px;
    border: 1px solid rgba(22, 33, 62, 0.1);

    .hr-custom {
      border-top: 1px solid rgba(22, 33, 62, 0.1);
    }
  }

  .list-body {
    .hr-custom {
      margin-top: 0;
    }
  }

  .checkout-summary,
  .credit-form {
    border-radius: 20px;
  }

  .checkout-summary {
    max-width: 450px;
  }

  .pa-1 {
    padding-bottom: 1px;
  }

  .img-not-found {
    width: 70px;
  }

  .btn-add {
    margin-left: 5px;
    color: $primary;
  }

  .btn-add-icon {
    color: $primary;
  }

  .error-message-stripe{
    margin: 24px 0 0;
    background: rgba(225, 28, 78, .1);
    border-radius: 20px;
    padding: 1rem 2rem;
    color: #000;

    .content-error {
      margin-left: 6px;
      margin-bottom: 0;
    }
  }

  .warning-has-step{
    background-color: #fff;
    padding: 24px 24px 0 24px;

    .warning-message{
      margin: 0;
    }
  }

  .warning-message {
    background: rgba(251, 176, 59, 0.1);
    border-radius: var(--border-radius-base);
    padding: 16px;
    max-width: 900px;
    margin-bottom: 2rem;
  }
</style>
