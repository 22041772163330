<template>
  <b-row class="header mb-2">
    <b-col
      cols="12"
      class="d-flex"
    >
      <div
        class="step1 d-flex align-items-center cursor-pointer"
        @click="onClick"
      >
        <div class="step-number">
          1
        </div>
        <span
          data-testid="title-step-choose-plan"
          class="step-title"
        >{{ $t('pricingPage.textChoosePlan') }}</span>
        <feather-icon
          class="cursor-pointer"
          size="18"
          icon="ChevronRightIcon"
        />
      </div>
      <div class="step2 d-flex align-items-center">
        <div class="step-number">
          2
        </div>
        <span class="step-title">{{ $t('payment.textCheckout') }}</span>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },

  props: {
    step: {
      type: Number,
      default: 1,
    },
    showCheckoutPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSuccessPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    onClick() {
      this.$emit('update:showCheckoutPage', false)
      this.$emit('update:showSuccessPage', false)
    },
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/variables/_variables.scss';
  .step-number{
    width: 20px;
    height: 20px;
    background: $green;
    border-radius: 50px;
    line-height: 20px;
    color: white;
    font-size: 12px;
    text-align: center;
  }

  .step1{
    margin-right: 8px;
  }

  .step-title{
    margin: 0 8px;
  }

  .money{
    font-size: 24px;
  }

  @media (max-width: 767px) {
    .right{
      margin-top: 20px;
    }
  }
</style>
